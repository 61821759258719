<template>
  <div>
    <disposal-selected-waste-list></disposal-selected-waste-list>
  </div>
</template>

<script>
import DisposalSelectedWasteList from "@/components/ecologist/disposal/disposalSelectedWasteList";
export default {
  name: "disposalSelectedWasteListView",
  components: {DisposalSelectedWasteList}
}
</script>

<style scoped>

</style>